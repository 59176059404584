<template>
    <div class="introduce">
        <div class="banner">
            <img src="../assets/PC/introduction_img_banner.png" alt="">
            <div class="text" :style="textLeft">
                <h2>依蓝云公司</h2>
                <h4>用优秀创意为社会创造价值</h4>
            </div>
        </div>
        <div class="bannerH5">
            <h3>依蓝云公司</h3>
            <h5>用优秀创意为社会创造价值</h5>
        </div>
        <!-- 导航 -->
        <div class="introduceTap">
            <div class="main">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="公司简介" name="one"></el-tab-pane>
                    <el-tab-pane label="使命及愿景" name="two"></el-tab-pane>
                    <el-tab-pane label="发展历程" name="three"></el-tab-pane>
                    <el-tab-pane label="业务架构" name="four"></el-tab-pane>
                    <el-tab-pane label="企业文化" name="five"></el-tab-pane>
                    <el-tab-pane label="办公地点" name="six"></el-tab-pane>
                </el-tabs>
            </div>
        </div>

        <!-- 简介 -->
        <div class="us">
            <div class="main">
                <h2>公司简介</h2>
                <p>依蓝云创立于2006年，是一家创新型互联网科技公司，公司秉承文化创意与科技相结合服务社会的宗旨，用优秀有创意的产品及服务为社会创造价值。依蓝云由北京依蓝云科技有限公司、青岛依蓝云科技有限公司和青岛依蓝云计算有限公司组成。</p>
                <em style="display: block;height: 130px;"></em>
                <div>
                    <img src="../assets/PC/about_img_logo.png" class="logo" alt="">
                </div>
                <TreeChart class="Bifurcation" :json="treeData" />
                <div class="p">依蓝云创立于2006年，是一家创新型互联网科技公司，公司秉承文化创意与科技相结合服务社会的宗旨，用优秀有创意的产品及服务为社会创造价值。依蓝云由北京依蓝云科技有限公司、青岛依蓝云科技有限公司和青岛依蓝云计算有限公司组成。</div>
            </div>
        </div>

        <!-- 使命及愿景 -->
        <div class="mission">
            <div class="main">
                 <img src="../assets/PC/introduce1.png" alt="">
                  <div class="r">
                      <h2>使命及愿景</h2>
                      <p class="p">用优秀创意，为社会创造价值。</p>
                      <p>将带有社会责任感的创新产品和服务带给每个人、每个组织，促进各行各业发展。</p>
                  </div>
            </div>
        </div>

        <!-- 发展历程 -->
        <div class="development">
            <div class="enter">
                <h3>发展历程</h3>
                <div class="line">
                    <el-tabs v-model="lineNumber" @tab-click="lineClick">
                    <el-tab-pane label="2021" name="one"></el-tab-pane>
                    <el-tab-pane label="2020" name="two"></el-tab-pane>
                    <el-tab-pane label="2019" name="three"></el-tab-pane>
                    <el-tab-pane label="2018" name="four"></el-tab-pane>
                    <el-tab-pane label="2017" name="five"></el-tab-pane>
                    <el-tab-pane label="2009" name="six"></el-tab-pane>
                    <el-tab-pane label="2008 " name="seven"></el-tab-pane>
                </el-tabs>
                </div>
            </div>
            <div class="announcement">
                <div class="cont">
                    <div style="    position: relative;">
                    <div
                        class="contlist"
                        ref="contlist"
                        :style="textLeftScroll"
                        @touchend="touchendScroll"
                        @mouseover="stopScroll"
                        @mouseout="startScroll"
                    >
                    <!-- <div >
                        <img src="../assets/PC/progress_img_boat.png" alt="">
                    </div> -->
                    <div class="line"></div>
                        <ul>
                            <li class="contImg"><img src="../assets/PC/progress_img_boat.png" alt=""></li>
                            <li v-for="(item, index) in list" :id="item.conlistNumber" :key="index" @click="clickScroll(item.type)">
                                <div :class="item.type==lineNumber?'scrollLi active':'scrollLi'" v-if="(index+1)%2 ==0">
                                    <div class="p">{{ item.name }}</div>
                                    <div class="number"> {{ item.time }} </div>
                                </div>
                                <div :class="item.type==lineNumber?'scrollLi active':'scrollLi'" id="lineNumberTop" style="margin-top: 175px;" v-if="(index+1)%2 !=0">
                                    <div class="number"> {{ item.time }} </div>
                                    <div class="p">{{ item.name }}</div>
                                </div>
                            </li>
                        </ul>
                        <ul style="margin-left:400px" class="ul2">
                            <li class="contImg" style="width: 88px;"><img src="../assets/PC/progress_img_boat.png" alt=""></li>
                        <li v-for="(item, index) in list" :key="index" @click="clickScroll(item.type)">
                            <div :class="item.type==lineNumber?'scrollLi active':'scrollLi'" v-if="(index+1)%2 ==0">
                                <div class="p">{{ item.name }}</div>
                                <div class="number"> {{ item.time }} </div>
                            </div>
                            <div :class="item.type==lineNumber?'scrollLi active':'scrollLi'" id="lineNumberTop" style="margin-top: 175px;" v-if="(index+1)%2 !=0">
                                <div class="number"> {{ item.time }} </div>
                                <div class="p">{{ item.name }}</div>
                            </div>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 业务架构 -->
        <div class="framework">
            <div class="main">
                <h2>业务架构</h2>
                <img src="../assets/PC/about_img_logo.png" class="logo" alt="">
                <div class="work">
                    <numChart class="Bifurcation" :json="treeDatas" />
                </div>
                <div class="work_con">
                    <van-collapse v-model="activeNames">
                        <van-collapse-item name="1">
                            <template #title>
                            <div>
                                <div class="title">事业一部</div>
                                <em>可蚁点平台运营为主，获取县域城市大数据并创造价值。互联网项目定制开发、运维及运营工作。</em>
                            </div>
                            </template>
                            <ul class="collapse">
                                <li>
                                    <img src="../assets/PC/ic_product.png" alt="">
                                    <div class="li_con">
                                        <div class="tit">产品组</div>
                                        <em>可蚁点生活出行平台产品规划、技术研发和上线维护工作，以及其他互联网定制项目规划协助工作。</em>
                                    </div>
                                </li>
                                <li>
                                    <img src="../assets/PC/ic_sale.png" alt="">
                                    <div class="li_con">
                                        <div class="tit">销售组</div>
                                        <em>可蚁点生活出行平台县域城市市场推广落地工作，以及其他互联网定制项目推广协助工作。</em>
                                    </div>
                                </li>
                                <li>
                                    <img src="../assets/PC/ic_operate.png" alt="">
                                    <div class="li_con">
                                        <div class="tit">运营组</div>
                                        <em>可蚁点生活出行平台县域城市市场运营工作，以及其他互联网定制项目运营协助工作。</em>
                                    </div>
                                </li>
                            </ul>
                        </van-collapse-item>
                        <van-collapse-item name="2">
                            <template #title>
                            <div>
                                <div class="title">事业二部</div>
                                <em>可蚁点企业版平台运营为主，获取县域城市大数据并创造价值。互联网项目定制开发、运维及运营工作。</em>
                            </div>
                            </template>
                            <ul class="collapse">
                                <li>
                                    <img src="../assets/PC/ic_product.png" alt="">
                                    <div class="li_con">
                                        <div class="tit">产品组</div>
                                        <em>可蚁点企业版定制客运平台产品规划、技术研发和上线维护工作，以及其他互联网定制项目规划协助工作。</em>
                                    </div>
                                </li>
                                <li>
                                    <img src="../assets/PC/ic_sale.png" alt="">
                                    <div class="li_con">
                                        <div class="tit">销售组</div>
                                        <em>可蚁点企业版定制客运平台推广工作，以及其他互联网定制项目推广协助工作。</em>
                                    </div>
                                </li>
                                <li>
                                    <img src="../assets/PC/ic_operate.png" alt="">
                                    <div class="li_con">
                                        <div class="tit">运营组</div>
                                        <em>可蚁点企业版定制客运平台运营工作，以及其他互联网定制项目运营协助工作。</em>
                                    </div>
                                </li>
                            </ul>
                        </van-collapse-item>
                    </van-collapse>
                </div>
                <p>事业一部：可蚁点平台运营为主，获取县域城市大数据并创造价值。互联网项目定制开发、运维及运营工作。</p>
                <p>事业二部：可蚁点企业版平台运营为主，获取县域城市大数据并创造价值。互联网项目定制开发、运维及运营工作。</p>
            </div>
        </div>

        <!-- 企业文化 -->
        <div class="Culture">
            <h2 class="h2">企业文化</h2>
             <img src="../assets/PC/introduce2.png" class="l" alt="">
             <div class="r">
                 <h2>企业文化</h2>
                 <ul>
                     <li>
                         <div class="title">真诚</div>
                         <p>坚持底线，品德为先，公正坦诚</p>
                     </li>
                     <li>
                         <div class="title">上进</div>
                         <p>永不自满，善于学习，突破自我</p>
                     </li>
                     <li>
                         <div class="title">担当</div>
                         <p>承担责任，无畏艰辛，迎难而上</p>
                     </li>
                     <li>
                         <div class="title">协同</div>
                         <p>分享协作，共同学习，取长补短</p>
                     </li>
                 </ul>
             </div>
        </div>

        <!-- 办公地点 -->
        <div class="map">
            <div class="main">
                <h2>办公地点</h2>
                <div class="amap-wrapper">
                    <el-amap 
                    class=
                    "amap-box" 
                    vid=
                    "map" 
                    :zoom="zoom"
                    :center="center">
                    <el-amap-marker vid="component-marker"
                    :position="center" 
                    :offset="[-50,-50]"
                    :icon="iconImg">
                    </el-amap-marker>
                    </el-amap>
                </div>

                <div class="text">
                    <el-tabs v-model="activemap" @tab-click="mapClick">
                        <el-tab-pane label="青岛" name="one">
                            <div class="mapContent">
                                青岛依蓝云科技有限公司（总部）<br>
                                总部地址：山东省青岛市高新区火炬路97号1层102室<br>
                                邮编：266200<br>
                                <!-- 总机电话：0532-58661717<br> -->
                                <div class="mapHeigth"></div>
                                青岛依蓝云计算有限公司<br>
                                公司地址：山东省青岛市高新区火炬路97号1层103室<br>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="北京" name="two">
                            <div class="mapContent">
                                依蓝云（北京）科技有限公司<br>
                                公司地址：北京市东城区安定门东滨河路3号院6号楼3单元305室<br>
                                邮编：100000<br>

                            </div>
                        </el-tab-pane>
                        <!-- <el-tab-pane label="临沂" name="three">
                            <div class="mapContent">
                                青岛依蓝云科技有限公司（分部）<br>
                                公司地址：山东省临沂市兰山区金雀山路10号开元上城B座1916室<br>
                                邮编：276000<br>

                            </div>
                        </el-tab-pane> -->
                    </el-tabs>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iconImg from "../assets/PC/located_img_logo.png";
import TreeChart from "@/components/TreeChart";
import numChart from "@/components/numChart";
export default {
    props:['scrollTop'],
    data () {
        return {
            textLeft:'left:0px',
            textLeftScroll:'left:0px',
            activeName: 'one', //
            lineNumber: '',// 发展历程
            activemap: 'one', //地图对应相关信息
            iconImg:iconImg,
            activeNames: [],
            scrollNumber:this.scrollTop,
            treeDatas:{
                name: '青岛依蓝云科技有限公司',
                image_url:require('../assets/PC/about_img_logo.png'),
                class: ["rootNode"],
                children: [
                    { name: '事业一部', 
                        children: [
                            { name: '产品组',class: ["rootNodeList"],image_url:require('../assets/PC/ic_product.png'),text:'可蚁点生活出行平台产品规划、技术研发和上线维护工作，以及其他互联网定制项目规划协助工作。' },
                            { name: '销售组',class: ["rootNodeList"],image_url:require('../assets/PC/ic_sale.png'),text:'可蚁点生活出行平台县域城市市场推广落地工作，以及其他互联网定制项目推广协助工作。'},
                            { name: '运营组',class: ["rootNodeList"],image_url:require('../assets/PC/ic_operate.png'),text:'可蚁点生活出行平台县域城市市场运营工作，以及其他互联网定制项目运营协助工作。'},
                        ]
                    },
                    { name: '事业二部', 
                        children: [
                            { name: '产品组',class: ["rootNodeList"],image_url:require('../assets/PC/ic_product.png'),text:'可蚁点企业版定制客运平台产品规划、技术研发和上线维护工作，以及其他互联网定制项目规划协助工作。' },
                            { name: '销售组',class: ["rootNodeList"],image_url:require('../assets/PC/ic_sale.png'),text:'可蚁点企业版定制客运平台推广工作，以及其他互联网定制项目推广协助工作。'},
                            { name: '运营组',class: ["rootNodeList"],image_url:require('../assets/PC/ic_operate.png'),text:'可蚁点企业版定制客运平台运营工作，以及其他互联网定制项目运营协助工作。'},
                        ]
                    },
                ]
            },
            treeData:{
                name: '青岛依蓝云科技有限公司',
                class: ["rootNode"],
                children: [
                { name: '青岛依蓝云计算有限公司', },
                { name: '依蓝云（北京）科技有限公司',}
                ]
            },
            scrollTime:'',//计时器
            list: [
                { name: "服务于国家外文局主管的中国报道平台，为其提供互联网应用定制、数据安全和云计算服务。", conlistNumber:'conlistNumber1',time: "2021",type:'one' },
                { name: "服务全球抗疫童书互译共读项目“生命树童书网”平台，为其提供应用定制、数据安全和云计算服务。", conlistNumber:'conlistNumber2',time: "2020",type:'two' },
                { name: "服务秋林集团，为其数字化转型提供品牌竞争力提升、技术方案实施落地、数据安全和云计算服务。", conlistNumber:'conlistNumber3',time: "2019",type:'three' },
                { name: "服务海信日立集团，为其数字化转型提供技术方案实施落地、数据安全和云计算服务。", conlistNumber:'conlistNumber4',time: "2018 ",type:'four' },
                { name: "上线以三、四、五线城市生活出行服务为主的平台产品“咱县打车（现名：可蚁点）”", conlistNumber:'conlistNumber5',time: "2017",type:'five' },
                { name: "上线以云安全为主，可弹性定制云计算产品服务平台。", conlistNumber:'conlistNumber6',time: "2009",type:'six' },
                { name: "服务2008北京奥运青岛奥帆中心，团队成员为其提供网络安全技术服务。", conlistNumber:'conlistNumber7',time: "2008",type:'seven' },
            ],
            scrollW: 0, //记录滚动到哪了
            scrollWOne: 0, //第一次记录
            // ------------高德地图参数开始-----------------
            zoom: 26, 
            center:[ 120.264398,36.258667],
            // ------------高德地图参数结束----------------
            scrollTopNumber:[580,1070,1546,2292,3278,3978], //5411
            conlistNumber:[0,354,711,1064,1418,1770,2130],
        }
    },
    components: {
        TreeChart,
        numChart,
	},
    watch: {
        "$route": "fetchDate",
        scrollTop (newV, oldV) { // watch监听props里滚动条到顶部距离
            if(newV.path=="/introduce"){
                let Numbers=newV.number
                if(Numbers<this.scrollTopNumber[1]-1){
                    this.activeName='one'
                }else if(Numbers>this.scrollTopNumber[1]-1 && Numbers<this.scrollTopNumber[2]){
                    this.activeName='two'
                }else if(Numbers>this.scrollTopNumber[2]-1 && Numbers<this.scrollTopNumber[3]){
                    this.activeName='three'
                }else if(Numbers>this.scrollTopNumber[3]-1 && Numbers<this.scrollTopNumber[4]){
                    this.activeName='four'
                }else if(Numbers>this.scrollTopNumber[4]-1 && Numbers<this.scrollTopNumber[5]){
                    this.activeName='five'
                }else{
                    this.activeName='six'
                }
            }
            
            
        }
    },

    mounted () {
        // 检测界面宽度设置left
        if(document.body.clientWidth>1270){
            this.textLeft='left:'+ (document.body.clientWidth-1270)/2 +'px'
        }else{
            this.textLeft='left:20px' 
        }
        window.onresize = () => {
            return (() => {
                if(document.body.clientWidth>1270){
                    this.textLeft='left:'+ (document.body.clientWidth-1270)/2 +'px'
                }else{
                    this.textLeft='left:20px'
                }
            })();
        };

        if(document.body.clientWidth>1270){
            this.conlistNumber=[
                0+(document.body.clientWidth-1270)/2,
                document.querySelector('#conlistNumber2').offsetLeft-10-(document.body.clientWidth-1270)/2,
                document.querySelector('#conlistNumber3').offsetLeft-10-(document.body.clientWidth-1270)/2,
                document.querySelector('#conlistNumber4').offsetLeft-10-(document.body.clientWidth-1270)/2,
                document.querySelector('#conlistNumber5').offsetLeft-10-(document.body.clientWidth-1270)/2,
                document.querySelector('#conlistNumber6').offsetLeft-10-(document.body.clientWidth-1270)/2,
                document.querySelector('#conlistNumber7').offsetLeft-10-(document.body.clientWidth-1270)/2,
            ]
        }else{
            this.conlistNumber=[
                0,
                document.querySelector('#conlistNumber2').offsetLeft-10,
                document.querySelector('#conlistNumber3').offsetLeft-10,
                document.querySelector('#conlistNumber4').offsetLeft-10,
                document.querySelector('#conlistNumber5').offsetLeft-10,
                document.querySelector('#conlistNumber6').offsetLeft-10,
                document.querySelector('#conlistNumber7').offsetLeft-10,
            ]
        }
        // console.log(this.$look())
        if(this.$look()){
            // 5459
            this.scrollTopNumber=[document.querySelector('.us').offsetTop+10,
            document.querySelector('.mission').offsetTop+10,
            document.querySelector('.development').offsetTop+20,
            document.querySelector('.framework').offsetTop+10,
            document.querySelector('.Culture').offsetTop+10,
            document.querySelector('.map').offsetTop+30]
        }
        // 滑动
        this.scroll();
        if(document.body.clientWidth>1270){
            this.$refs.contlist.style.left =  (document.body.clientWidth-1270)/2 + "px";
            this.scrollW=((document.body.clientWidth-1270)/2) * -1
            this.scrollWOne=(document.body.clientWidth-1270)/2
            
        }else{
            this.$refs.contlist.style.left = "-0px";
            this.scrollW=0
            this.scrollWOne=0
        }
        let this_=this;
     setTimeout(function() {
         if(this_.$route.query.herfIndex){
              window.scrollTo(0,this_.scrollTopNumber[this_.$route.query.herfIndex])
            //   console.log(this_.scrollTopNumber[this_.$route.query.herfIndex])
         }
      }, 10)

    },
    methods: {

        fetchDate(){
            if(this.$route.query.herfIndex){
                window.scrollTo(0,this.scrollTopNumber[this.$route.query.herfIndex])
            }
        },
        // 定位锚点
        handleClick(tab, event) {
            console.log(this.activeName)
            console.log(this.scrollTopNumber)
            if(this.activeName=='one'){
                window.scrollTo(0,this.scrollTopNumber[0])
            }else if(this.activeName=='two'){
                window.scrollTo(0,this.scrollTopNumber[1])
            }else if(this.activeName=='three'){
                window.scrollTo(0,this.scrollTopNumber[2])
            }else if(this.activeName=='four'){
                window.scrollTo(0,this.scrollTopNumber[3])
            }else if(this.activeName=='five'){
                window.scrollTo(0,this.scrollTopNumber[4])
            }else if(this.activeName=='six'){
                window.scrollTo(0,this.scrollTopNumber[5])
            }else{
                document.documentElement.scrollTop=0
                window.scrollTo(0,0)
                
            }
        },
        lineClick(tab, event) {
            // console.log(this.lineNumber)
            clearInterval(this.scrollTime);
            if(document.body.clientWidth>1270){
                if(this.lineNumber=='one'){
                    this.scrollW =this.conlistNumber[0];
                    this.$refs.contlist.style.left = + this.conlistNumber[0] + "px";
                }else if(this.lineNumber=='two'){
                    this.scrollW =this.conlistNumber[1];
                    this.$refs.contlist.style.left = "-"+ this.conlistNumber[1] + "px";
                }else if(this.lineNumber=='three'){
                    this.scrollW =this.conlistNumber[2];
                    this.$refs.contlist.style.left = "-"+ this.conlistNumber[2] + "px";
                }else if(this.lineNumber=='four'){
                    this.scrollW =this.conlistNumber[3];
                    this.$refs.contlist.style.left = "-"+ this.conlistNumber[3] + "px";
                }else if(this.lineNumber=='five'){
                    this.scrollW =this.conlistNumber[4];
                    this.$refs.contlist.style.left = "-"+ this.conlistNumber[4] + "px";
                }else if(this.lineNumber=='six'){
                    this.scrollW =this.conlistNumber[5];
                    this.$refs.contlist.style.left = "-"+ this.conlistNumber[5] + "px";
                }else{
                    this.scrollW =this.conlistNumber[6];
                    this.$refs.contlist.style.left = "-"+ this.conlistNumber[6] + "px";
                }
                
            }else{
                if(this.lineNumber=='one'){
                    this.scrollW =this.conlistNumber[0];
                    this.$refs.contlist.style.left = "-" + this.conlistNumber[0] + "px";
                }else if(this.lineNumber=='two'){
                    this.scrollW =this.conlistNumber[1];
                    this.$refs.contlist.style.left = "-" + this.conlistNumber[1] + "px";
                }else if(this.lineNumber=='three'){
                    this.scrollW =this.conlistNumber[2];
                    this.$refs.contlist.style.left = "-" + this.conlistNumber[2] + "px";
                }else if(this.lineNumber=='four'){
                    this.scrollW =this.conlistNumber[3];
                    this.$refs.contlist.style.left = "-" + this.conlistNumber[3] + "px";
                }else if(this.lineNumber=='five'){
                    this.scrollW =this.conlistNumber[4];
                    this.$refs.contlist.style.left = "-" + this.conlistNumber[4] + "px";
                }else if(this.lineNumber=='six'){
                    this.scrollW =this.conlistNumber[5];
                    this.$refs.contlist.style.left = "-" + this.conlistNumber[5] + "px";
                }else{
                    this.scrollW =this.conlistNumber[6];
                    this.$refs.contlist.style.left = "-" + this.conlistNumber[6] + "px";
                }
            }
            
        },
        mapClick(tab, event) {
            if(this.activemap=='one'){
                this.center=[120.264398,36.258667]
            }else if(this.activemap=='two'){
                this.center=[116.419948,39.950231]
            }else{
                this.center=[118.359393,35.050626]
            }
        },
        touchendScroll(){
            clearInterval(this.scrollTime);
            var target = this.$refs.contlist;
            this.scrollW = target.offsetLeft; // 移开时记录向左移动的距离
            this.scroll();
        },
        // 手机端点击停止
        clickScroll(type){
            if(this.$look()){
                this.lineNumber=type
                clearInterval(this.scrollTime);
            }
        },
        //鼠标悬停，停止滚动
        stopScroll() {
            if(this.$look()){
                return;
            }
            var target = this.$refs.contlist;
            clearInterval(this.scrollTime);
        },
        //鼠标移开 ，接着滚动
        startScroll() {
            if(this.$look()){
                return;
            }
            var target = this.$refs.contlist;
            this.scrollW = target.offsetLeft; // 移开时记录向左移动的距离
            this.scroll();
        },
        //循环滚动
        scroll() {
            var that = this; //因为定时器里要使用vue对象时不能用this, 在定时器中的 this 代表
            var target = this.$refs.contlist;
            var initLeft = 0;
            
            if (this.scrollW != 0 &&this.scrollW!='') {
                initLeft = this.scrollW
            }
            if(this.$look()){
                //定时器
                this.scrollTime = setInterval(function () {
                    initLeft--;

                    if ((initLeft*-1) >= (target.offsetWidth / 2)+400) {
                    initLeft = that.scrollWOne;
                    }
                    target.style.left =  initLeft + "px"; //获取不到translateX的值改用 left

                    //target.style = 'transform: translateX(-'+ initLeft +'px)';
                }, 24);
            }else{

                //定时器
                this.scrollTime = setInterval(function () {
                    initLeft--;
    
                    if ((initLeft*-1) >= (target.offsetWidth / 2)+400) {
                    initLeft = that.scrollWOne;
                    }
                    target.style.left =  initLeft + "px"; //获取不到translateX的值改用 left
    
                    //target.style = 'transform: translateX(-'+ initLeft +'px)';
                }, 16);
            }
            }
    },
    //  destroyed() {
    //   window.removeEventListener('scroll', this.handleScroll);
    // }
}
</script>
<style lang="less" scoped>

    .cont {
        
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        // padding-left: 94px;
        height: 310px;
        overflow: hidden;
        position: relative;
        .contImg{
            // position: absolute;
            left: 0;
            top: -2px;
            width:94px;
            height: 100%;
            line-height: 240px;
            background-color: #fff;
            z-index: 1;
            img{
                width:94px;
            }
        }
        .contlist {
            position: absolute;
            width: 5200px;
            display: flex;
            flex-direction: row;
            .line{
                position: absolute;
                top: 148px;
                left: -5px;
                width: 6000px;
                height: 1px;
                background-color: #009cff;
            }
            ul {
                display: flex;
                flex-direction: row;
                li.contImg{
                    width: auto;
                    
                    
                }
                li.contImg::after{
                    display: none;
                }
                li {
                    position: relative;
                    font-size: 12px;
                    margin-right: 25px;
                    width: 330px;
                    height: 240px;
                    // display: flex;
                    // flex-direction: row;
                    .scrollLi.active{
                        color: #009cff;
                    }
                    .scrollLi{
                        .number{
                            font-size: 24px;
                            margin: 20px 0;
                        }
                        .p{
                            float: left;
                            width: 430px;
                            font-size: 16px;
                            word-wrap:break-word;
                        }

                    }
                }
                li:hover{
                    color: #009cff;
                }
                li::after{
                    content: '';
                    position: absolute;
                    left:0;
                    width: 10px;
                    height: 10px;
                    background-color: #009cff;
                    border-radius: 50%;
                    top: 144px;
                }
            }
        }
    }
    
    .introduce{
        min-height: 600px;
        margin-top: 70px;
        /deep/.el-tabs__active-bar{
            // width: 32px!important;
            height: 3px;
        }
        .bannerH5{
            display: none;
        }
        .banner{
            position: relative;
            width: 100%;
            img{
                width: 100%;
                height: 476px;
                // float: left;
            }
                .text{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateY(-50%);
                    
                    h2{
                        font-size: 64px;
                        // width: 1270px;
                        font-weight: 400;
                        color: #fff;
                        float: inherit;
                        border: 0px;
                        padding-left: 0;
                    }
                    h2::after{
                        display: none;
                    }
                    h4{
                        // width: 1270px;
                        font-weight: 400;
                        font-size: 24px;
                        color: #fff;
                        margin-top: 40px;
                    }
                }
        }
        .ul2{
            position: relative;
        }
        .ul2::after{
            position: absolute;
            content: '';
            width: 400px;
            left: -310px;
            height: 100%;
            background-color: #fff;
        }
        // tab切换
        .introduceTap{
            border-bottom: 1px solid #e6e6e6;
            /deep/.el-tabs__header{
                margin: 0;
            }
            /deep/.el-tabs__item{
                position: relative;
                height: 70px;
                line-height: 70px;
                padding: 0 40px; 
                font-size: 16px;
            }
            /deep/.el-tabs__item::after{
                content: '';
                position: absolute;
                width: 1px;
                height: 16px;
                background-color: #e6e6e6;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            /deep/.el-tabs__item:nth-child(2){
                padding-left: 0;
            }
            /deep/.el-tabs__item:nth-child(7)::after{
                content: '';
                position: absolute;
                width: 1px;
                height: 16px;
                background-color: transparent;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            /deep/.el-tabs__nav-wrap::after{
                background-color: transparent;
            }
        }

        h2{
            position: relative;
            float: left;
            padding-left: 30px;
            font-size: 36px;
            font-weight: normal;
            // border-left: 8px solid #009cff;
        }
        h2::after{
            content: '';
            position: absolute;
            width: 8px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 36px;
            background-color: #009cff;
        }
        // 简介
        .us{
            padding-top:70px;
            text-align: center;
            p{
                display: block;
                float: right;
                width: 870px;
                text-align: justify;
                font-size: 16px;
            }
            .p{display: none;}
            .logo{
                width: 163px;
                margin-bottom: 30px;
            }
            .Bifurcation{
                margin: 0 auto; 
                display: inline-block;
            }
            /deep/.rootNode .name{
                background-color: transparent;
            }
            /deep/.node .rootNode{
                background-color: transparent;
                padding-bottom: 10px;
            }
        }

        // 使命及愿景
        .mission{
            padding-top: 60px;
            img{
                width: 50%;
                height: 320px;
                vertical-align: bottom;
            }
            .r{
                display: inline-block;
                width: 50%;
                height: 320px;
                box-sizing: border-box;
                padding: 40px 56px;
                background-color: #f8f8f8;
            }
            p{
                font-size: 16px;
                font-weight: normal;
            }
            .p{
                margin-top: 100px;
                margin-bottom: 40px;
            }
        }

        // 发展历程
        .development{
            padding-top: 120px;
            .enter{
                overflow: hidden;
                background: linear-gradient(to right,#009cff,rgba(0, 132, 255, 1));
                padding-bottom: 60px;
                h3{
                    text-align: center;
                    font-size: 36px;
                    color: #ffffff;
                    margin: 48px 0 60px;
                    font-weight: 400;
                }
                .line{
                    width: 880px;
                    margin: 0 auto;
                    /deep/.el-tabs__nav{
                        width: 920px;
                        // overflow: hidden;
                        transform: translateX(0px) !important;
                    }
                    /deep/.el-tabs__nav-scroll{
                        overflow: hidden;
                    }
                    /deep/.el-tabs__item{
                        box-sizing: border-box;
                        position: relative;
                        width: 130px;
                        height: 70px;
                        padding:0;
                        color: #fff;
                        font-size: 16px;
                        
                    }
                    /deep/.el-tabs__item:nth-child(8)::before{
                        display: none;
                    }
                    /deep/.el-tabs__item::after{
                        content: '';
                        position: absolute;
                        background:url('../assets/PC/progress_ic_logo.png') no-repeat;
                        background-size: 100% 100%;
                        width: 24px;
                        height: 21px;
                        bottom: 0px;
                        left: 9px;
                        opacity: 0.6;
                    }
                    /deep/.el-tabs__item::before{
                        content: '';
                        position: absolute;
                        width: 72px;
                        height: 1px;
                        background-color: #ffffff;
                        opacity: 0.2;
                        bottom: 10px;
                        left: 50px;
                    }
                    // /deep/.el-tabs__item:nth-child(7)::before{
                    //     background-color: transparent;
                    // }
                    /deep/.el-tabs__item.is-active::after{
                        opacity: 1;
                    }
                    /deep/.el-tabs__item.is-active{
                        font-size: 24px;
                        color: #fff;
                    }
                    /deep/.el-tabs__nav-wrap::after{
                        background-color: transparent;
                    }
                    /deep/.el-tabs__active-bar{
                        display: none;
                    }
                }
            }
            .announcement{
                padding:64px 0;
            }
        }

        // 业务架构
        .framework{
            background-color: #f8f8f8;
            padding: 48px 0;
            /deep/.rootNodeList .avatList{
                display: block;
            }
            min-height: 800px;
            .logo{
                display: none;
            }
            .work_con{
                display: none;
            }
            h2{
                float: inherit;
            }
            .work{
                height: 640px;
                position: relative;
            }
            .Bifurcation{
                margin: 30px 0 0;
                position: absolute;
                width: 100%;
                left: -21px;
            }
            p{
                position: relative;
                padding-left: 33px;
                font-size: 14px;
                margin-bottom: 24px;
            }
            p::after{
                content: '';
                position: absolute;
                left:0;
                width: 8px;
                height: 8px;
                background-color: #009cff;
                border-radius: 50%;
                top: 6px;
            }
        }

        // 企业文化
        .Culture{
            width: 100%;
            height: 640px;
            background-color: #f8f8f8;
            margin-top: 100px;
            .h2{
                display: none;
            }
            .l{
                width: 50%;
                height: 640px;
            vertical-align: top;
            }
            .r{
                height: 640px;
                display: inline-block;
                width: 50%; 
                box-sizing: border-box;
                padding-left: 58px;
                h2{
                    margin-top: 38px;
                    float: inherit;
                }
                ul{
                    margin-top: 40px;
                    li{
                        margin-bottom: 50px;
                        .title{
                            font-size: 24px;
                        }
                        p{
                            font-size: 16px;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }

        //办公地点
        .map{
            margin-top: 120px;
            h2{
               float: inherit;
               margin-bottom: 72px;
            }
            .amap-wrapper{
                max-width: 1270px;
                height: 480px;
            }
            .text{
                margin-top: 64px;
                margin-bottom: 120px;
            }
            .mapContent{
                font-size: 16px;
                line-height: 25px;
                .mapHeigth{
                    height: 20px;
                }
            }
            /deep/ .amap-icon img {
                max-width: 100px !important;
            }
            /deep/.el-tabs__item{
                font-size: 24px;
                height: 100px;
                line-height: 100px;
                padding-right: 120px;
                color: #999;
            }
            /deep/.el-tabs__item.is-active{
                color: #333;
            }
            /deep/.el-tabs__active-bar{
                top: 0;
            }
            /deep/.el-tabs__nav-wrap::after{
                top: 0;
            }
        }
    }

     // 手机端样式
    #app.H5Class {
        .introduce{
            padding-top: 0.8rem;
            margin-top: 0;
        }
        
        /deep/.us{
            padding-top: 0;
        }
        .banner{
            display: none;
        }
        .bannerH5{
            overflow: hidden;
            width: 100%;
            display: block;
            height: 5.6rem;
            background:url(../assets/H5/introduction_img_banner.png) no-repeat;
            background-size: 100% 100%;
            h3{
                margin-top: 1.2rem;
                color: #fff;
                font-size: 0.6rem;
                font-weight: 400;
                padding-left: 0.4rem;
            }
            h5{
                margin-top: 0.3rem;
                color: #fff;
                padding-left: 0.4rem;
                font-size: 0.24rem;
                font-weight: 400;
            }
        }
        .introduceTap{
            /deep/.el-tabs__item{
                padding: 0 0.4rem;
            }
            /deep/.el-tabs__item:nth-child(2){
                    padding-left: 0;
            }
            /deep/.el-tabs__item:hover{
                color: #333;
            }
            /deep/.el-tabs__item.is-active{
                color: #409EFF;
            }
        }
        .contlist{
            height: 3rem;
            .line{
                top: 1.8rem;
                left: 54px;
            }
            li{
                width: 3.2rem;
                // height: 1.6rem;
                height: auto;
                #lineNumberTop{
                    margin-top: 2.1rem!important;
                }
                .p{
                    width: 5.3rem;
                }
            }

            li::after{
                top: 1.7rem;
            }
        }
        .introduce{
            /deep/.el-tabs__item{
                font-size: 0.3rem;
                height: 1.05rem;
                line-height: 1.05rem;
            }
            /deep/.el-tabs__nav-scroll{
                overflow-x: scroll;
                transform:none
            }
            ::-webkit-scrollbar {
                /*隐藏滚轮*/
                display: none;
                }
            /deep/.el-tabs__nav-next{
                display: none;
            }
            /deep/.el-tabs__nav-prev{
                display: none;
            }
            /deep/.el-tabs__nav-wrap.is-scrollable{
                padding: 0 0.4rem;
            }
        }

        .us{
            width: 6.9rem;
            margin: 0px auto;
            text-align: center;
            position: relative;
            em{
                height: 0!important;
            }
            p{
                display: none;
            }
            .p{
                display: block;
                font-size: 0.24rem;
                text-align: left ;
                    line-height: 1.7;
                    margin-top: 3.2rem;
            }
            .logo{
                margin-top: 0.9rem;
                width: 2rem;
                margin-bottom: 0.1rem;
            }
            .Bifurcation{
                position: absolute;
                left: -0.2rem;
                width: 7.2rem;
                overflow: hidden;
            }
        }
        h2{
            font-size: 0.34rem;
            float: inherit;
            text-align: left;
            padding-left: 0.24rem;
            border-left: 0.08rem solid #009cff;
        }
        h2::after{
            display: none;
        }

        .mission{
            position: relative;
            margin-top: 1rem;
            padding-top: 0;
            img{
                width: 100%;
                height: 5.6rem;
            }
            .r{
                width: 6.7rem;
                position: absolute;
                right: 0;
                top: 50%;
                height: auto;
                padding: 0.8rem 0.4rem;
                transform: translateY(-50%);
                background-color: rgba(255, 255, 255, 0.8);
                h2{
                    margin-top: 0.4rem;
                }
                p{
                    font-size: 0.24rem;
                }
                .p{
                    margin-top: 0.7rem;
                    margin-bottom:0rem;
                    font-weight: 700;
                }
            }
        }

        .development{
            padding-top: 1.2rem;
            .enter{
                padding-bottom:0.7rem;
                h3{
                    font-size: 0.34rem;
                    margin-top:0.8rem ;
                    margin-bottom: 00.7rem;
                }
                .line{
                    /deep/ .el-tabs__nav{
                        width: 22rem;
                    }
                    /deep/ .el-tabs__item{
                        line-height: .4rem;
                        width: 1.6rem;
                    }
                    /deep/ .el-tabs__item::after{
                        bottom: 00.1rem;
                        left: 0.2rem;
                        width: 0.4rem;
                        height: 0.35rem;
                    }
                    /deep/.el-tabs__item:last-child::before{
                        display: none;
                    }
                    /deep/.el-tabs__item::before{
                        width: 0.8rem;
                        height: 0.01rem;
                        background-color: #ffffff;
                        opacity: 0.2;
                        left: 0.7rem;
                    }
                }
            }
            .announcement{
                .cont{
                    height: 4rem;
                    li.contImg{
                        width: 1.2rem;
                        line-height: 2.9rem;
                        height: 4rem;
                        background-color: transparent;
                        img{
                            width: 1.2rem;
                        }
                    }
                    .ul2{
                        .contImg{
                            top: 0.12rem;
                            background-color: #fff;
                            width: 1.2rem!important;
                            margin: 0;
                            img{
                                width: 1.2rem;
                            }
                        }
                    }
                    .ul2::after{
                        width: 5.2rem;
                        left: -5.2rem;
                    }
                }
                padding: 0.7rem 0;
                // padding-left: 0.4rem;
                .p{
                    font-size: 0.24rem;
                    margin: 0.2rem 0;
                    text-align: justify;
                }
                .number{
                    font-size: 0.28rem;
                    font-weight: 700;
                    margin: 0;
                }
            }
        }
        .framework{
            padding: 0.4rem;
            text-align: center;
            min-height:6.6rem;
            /deep/.van-hairline--top-bottom::after{
                display: none;
            }
            /deep/.van-hairline-unset--top-bottom::after{
                display: none;
            }
            /deep/.van-collapse-item--border::after{
                display: none;
            }
            .work{
                display: none;
            }
            .logo{
                display: block;
                width: 2.7rem;
                margin: 0.9rem auto 0.6rem;
            }
            p{
                display: none;
            }
            .work_con{
                display: block;
                text-align: left;
                /deep/.van-collapse-item{
                    margin-bottom:0.3rem ;
                }
                /deep/.van-collapse-item__content{
                    padding-top: 0.6rem;
                }
                .title{
                    font-size: 0.3rem;
                }
                em{
                    display: inline-block;
                    font-size: 0.24rem;
                    margin-top: 0.3rem;
                }
                /deep/.van-cell{
                    padding: 0.4rem 0.3rem;
                }
                /deep/.van-cell__left-icon,
                /deep/ .van-cell__right-icon{
                    font-size: 0.3rem;
                }
                .collapse{
                    li{
                        position: relative;
                        box-sizing: border-box;
                        padding-left: 1rem;
                        margin-bottom: 0.8rem;
                        img{
                            position: absolute;
                            left: 0.1rem;
                            top: 0%;
                            width: 0.5rem;
                        }
                        .tit{
                            color: #666;
                            font-size: 0.24rem;
                        }
                        em{
                            color:#999;
                            font-size: 0.24rem;
                            margin-top: 0.3rem;
                        }
                    }
                }
            }
        }

        .Culture{
            background-color: #fff;
            height: auto;
            margin-top: 1.2rem;
            h2{
                display: none;
            }
            .h2{
                box-sizing: border-box;
                width: 6.8rem;
                margin: 0 auto 0.8rem;
                display: block;
                background-color: #fff;
            }
            img{
                width: 100%;
                height: 4rem;
            }
            .r{
                width: 100%;
                text-align: center;
                background-color:#f8f8f8;
                height: auto;
                padding: 0;
                .title{
                    font-size: 0.3rem;
                }
                p{
                    font-size: 0.2rem;
                }
                ul{
                    padding-bottom: 0.9rem;
                    li{
                        margin-top: 00.6rem;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .map{
            margin-top: 0.8rem;
            h2{
               margin-left: 0.4rem; 
               margin-bottom: 0.8rem;
            }
            .amap-wrapper{
                height: 4rem;
            }
            .text{
                margin-top: 0; 
                margin-bottom:1.2rem;
            }
            /deep/.el-tabs__active-bar{
                top: auto;
                height: 0.03rem;
            }
            /deep/.el-tabs__nav-wrap::after{
                top: auto;
                height: 0.01rem;
            }
            /deep/.el-tabs__item{
                padding: 0.3rem 0.4rem;
                height: auto;
                line-height: 1;
            }
            .mapContent{
                padding: 0.4rem;
                font-size: 0.24rem;
            }
        }
    }
</style>